<script>
import Layout from "../../../layouts/main";
import Multiselect from "vue-multiselect";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { ref, onMounted } from '@vue/composition-api'
import useHttp from '@/comp-functions/useHttp'
import readXlsxFile from 'read-excel-file'
import router from '@/router'
import Swal from "sweetalert2";

export default {
  page: {
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Multiselect,
    Layout,
    PageHeader,
  },
  setup() {
    const { $get } = useHttp()
    const { $post } = useHttp()
    // const inputFile = ref({})
    let userLogin = JSON.parse(localStorage.getItem('user'))
    const formDisplay = ref({
      vendorName : userLogin.companyname
    })
    const form = ref({
      details:[]
    })
    const valAircrafttype = ref({})
    const LOV = ref({
      airCrafttype: []
    })
    const getAircraftType = async () => {
      const {data} = await $get({ url: 'master/codes/category/ACT'})
      LOV.value.airCrafttype = data
    }
    onMounted(() => {
     getAircraftType()
    })

const onFileChange = async () => {
    let txtfile = document.getElementById('txtfile')
    readXlsxFile(txtfile.files[0]).then((rows) => {
      rows.splice(0, 1)
      rows.map(function(value) {
        form.value.details.push({
              supdOrigportinit : value[0],
              supdDestportinit : value[1],
              supdFlightno: value[2],
              supdDeparturedatetime: value[3],
              supdArrivaldatetime: value[4],
              supdTransit: value[5]
        })
      });
    })
  }

    const resetForm = ()=> {
      form.value = {details:[]}
    }
    
    const postForm = async () => {
      if(!valAircrafttype.value.lbc_code){
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Aircraft Type is required",
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        return false
      }
      if(!form.value.details[0]){
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Please upload the schedule data first",
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        return false
      }
      const response = await $post({ url: 'master/schedule',
        data: {...form.value, aircrafttype : valAircrafttype.value.lbc_code}
      })
      if(response.status === 'success'){
        router.push({name: 'appsmd-schedule-list'})
      }
    }
    return{
      postForm,
      form,
      formDisplay,
      resetForm,
      valAircrafttype,
      LOV,
      onFileChange
    }
  },
  methods: {},
  data() {
    return {
      title: "Flight Schedule Upload",
      items: [],
      select_label: "Select"
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form class="form-horizontal" role="form">
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Vendor"
                    label-for="txtvendorname"
                  >
                    <b-form-input id="txtvendorname" v-model="formDisplay.vendorName" disabled></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Aircraft Type"
                    label-for="txtaircrafttype"
                  >
                    <multiselect id="txtaircrafttype" v-model="valAircrafttype" :options="LOV.airCrafttype" label="descr_en" track-by="descr_en" :select-label="select_label"></multiselect>
                  </b-form-group>
                  
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="File"
                    label-for="txtfile"
                  >
                    <div class="custom-file">
                      <input id="txtfile" type="file" class="custom-file-input" @change="onFileChange" />
                      <label class="custom-file-label" for="txtfile">Choose file</label>
                    </div>
                  </b-form-group>
            <div class="table-responsive">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Origin</th>
                    <th>Destination</th>
                    <th>Flight No</th>
                    <th>Departure</th>
                    <th>Arrival</th>
                    <th>Transit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row,index) in form.details" :key="index">
                    <th scope="row" class="text-right">{{index+1}}</th>
                    <td class="text-center">{{row.supdOrigportinit}}</td>
                    <td class="text-center">{{row.supdDestportinit}}</td>
                    <td class="text-center">{{row.supdFlightno}}</td>
                    <td class="text-center">{{row.supdDeparturedatetime}}</td>
                    <td class="text-center">{{row.supdArrivaldatetime}}</td>
                    <td class="text-center">{{row.supdTransit}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
                </form>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <div class="button-items">
                  <b-button variant="primary" @click="postForm">
                    <i class="ri-save-line align-middle ml-2"></i>
                    Submit
                  </b-button>
                  <b-button variant="danger" @click="resetForm">
                    <i class="mdi mdi-refresh align-middle"></i> Reset
                  </b-button>
                  <b-button variant="danger" :to="{ name: 'appsmd-schedule-list'}">
                    <i class="ri-arrow-left-line align-middle mr-2"></i> Back
                  </b-button>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

  </Layout>
</template>